import React, {Component, Suspense} from 'react';

import _ from "lodash";
import actions from "@redux/electricity/actions";
import {connect} from "react-redux";
import {api} from "@redux/api";
import {Col, Collapse, Divider, Row} from "antd";
import {ElectricityColumns, ElectricityProps} from "@containers/UtilityData/Eletricity/ElectricityColumns";
import {ElectricityFormFields, ElectricityRelated} from "@containers/UtilityData/Eletricity/ElectricityFormFields";
import {Spinner} from "@components/Spinner";
import EditableTable from "@components/Table/EditableTable";
import UtilityDualAxes from "@containers/UtilityData/Charts/UtilityDualAxes.js";
import moment from "moment";
import EditableTableActions from "@redux/EditableTable/actions";

import {ElectricityFilters} from "@containers/UtilityData/Eletricity/ElectricityFilter";

const Histgram = React.lazy(() =>
    import("@containers/UtilityData/Charts/Histgram.js")
)
const LineChart = React.lazy(() =>
    import("@containers/UtilityData/Charts/LineChart.js")
)


const {Panel} = Collapse;

class Electricity extends Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            data: [],
            filter_dates: [],
            selected_months: [],
            selected_id: [],
            isLoading: false,
            tableRef: null,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.Selected_Rows !== this.props.Selected_Rows) {
            const selected_months = this.state.data
                .filter(e => this.props.Selected_Rows.includes(e.id))
                .map(e => moment(e.service_month).format('YY-MMM'));
            
            if (!_.isEqual(selected_months, this.state.selected_months)) {
                this.setState({ selected_months });
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // 只在 props 或 state 真正变化时才重新渲染
        return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState);
    }

    handleCreateElectricity = (body) => {
        return api.createElectricity(body).then(
            response => {
                // console.log('create', response);
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleDeleteElectricity = (id) => {
        return api.deleteElectricity(id).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handleBulkInsert = (body) => {
        return api.bulkElectricityInsert(body).then(
            response => {
                return response.data
            }
        ).catch(error => {
            return (
                error
            )
        });
    }

    handleBulkDelete = (body) => {
        return api.bulkDeleteElectricity(body).then(
            response => {
                return response.data
            }
        ).catch(error => (
            error
        ));
    }

    handOnlineSave = (key, row) => {
        if (key < 0) {
            this.handleCreateElectricity(row)
        } else {
            return api.editElectricity(key, row).then(
                response => {

                    return response
                }
            ).catch(error => (
                error
            ));
        }

    }


    handleFilter = (query) => {
        this.setState({
            isLoading: true
        })
        let query_new = query +
            (this.state.filtered_startModifiedDate ? ('&start_month=' + (this.state.filtered_startModifiedDate)) : '') +
            (this.state.filtered_endModifiedDate ? ('&end_month=' + (this.state.filtered_endModifiedDate)) : '');

        return api.filterElectricity(query_new).then(
            response => {
                let dataSource = response.data.results
                return {
                    data: dataSource,
                    success: true,
                    total: response.data.count
                }
            }
        ).catch(error => (
            error
        ));
    }

    getElectricity = (data) => {
        this.setState({
            data,
            isLoading: false
        })
        this.props.setElectricitySuccess(data)
    }

    setFilterDates = (dates) => {
        this.setState({
            filter_dates: dates
        })

    }

    handleChartSelect = (e) => {
        this.setState({
            selected_months: [e]
        })
        let id = _.get(this.state.data.filter(f => moment(f['service_month']).format('YY-MMM') === e)[0], 'id')
        this.setState({
            selected_id: id
        })
    }

    setFilters = (e) => {
        this.setState({
            filtered_startModifiedDate: e.startModifiedDate ? moment(e.startModifiedDate).format('YYYY-MM-DD') : [],
            filtered_endModifiedDate: e.endModifiedDate ? moment(e.endModifiedDate).format('YYYY-MM-DD') : [],
        }, function () {
            this.refresh(false)
        })
    }

    renderFilter = () => {
        return <ElectricityFilters
            handleFilter={this.setFilters}
        />
    }

    refresh = (needRequest) => {
        return this.tableRef?.current?.refresh(needRequest);
    };

    gettableRef = (tableRef) => {
        this.setState({
            tableRef
        })
    }

    render() {
        console.log('ServiceDateTemperature', '1')
        return (
            <>

                <Suspense fallback={<Spinner/>}>
                    <Suspense fallback={<Spinner/>}>
                        <Collapse defaultActiveKey={['col1', 'col0']} ghost>
                            <Panel key={'col0'} header={<Divider/>}>
                                <Row className={'w-full px-5'}>
                                    <Col xs={24} md={12} xl={8} className={''}>
                                        <div className={'mr-4'}>
                                            <Histgram
                                                area={this.props.Area}
                                                data={this.state.data}
                                                selected={this.state.selected_months}
                                                container='NormalizedElectricity'
                                                title='Normalized Electricity Use Vs. Weather'
                                                handleChartSelect={this.handleChartSelect}
                                                leftTitle={'Watt / sqft'}
                                                leftVar={'WattSqft'}
                                                height={232}
                                                pointSize={4}
                                                color={'blue'}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={24} md={12} xl={8} className={' p-6 mb-4'}>
                                        <div className={'mr-4'}>
                                            <UtilityDualAxes
                                                height={200}
                                                data={this.state.data}
                                                selected={this.state.selected_months}
                                                handleChartSelect={this.handleChartSelect}
                                                x={'service_month'}
                                                y={['usage', 'costs']}
                                                container='ElectricityCosts'
                                                title='Electricity Consumption and Costs'
                                                leftTitle='kWh'
                                                rightTitle='kWh'
                                                color={'blue'}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={24} md={12} xl={8} className={' p-6 mb-4'}>
                                        <div className={''}>
                                            <LineChart
                                                height={232}
                                                data={this.state.data}
                                                selected={this.state.selected_months}
                                                handleChartSelect={this.handleChartSelect}
                                                x={'service_month'}
                                                y={['costs', 'usage']}
                                                container='ElectricityPrices'
                                                title='Monthly Electricity Prices'
                                                leftTitle=''
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Suspense>
                    <EditableTable
                        key={'Electricity'}
                        ref={this.tableRef}
                        columns={ElectricityColumns}
                        data={this.state.data}
                        columnProps={ElectricityProps}
                        formFields={ElectricityFormFields(this.state.tableRef)}
                        formRelated={ElectricityRelated}
                        getRecord={this.getElectricity}
                        createRecords={this.handleCreateElectricity}
                        deleteRecord={this.handleDeleteElectricity}
                        bulkInsert={this.handleBulkInsert}
                        bulkDelete={this.handleBulkDelete}
                        handOnlineSave={this.handOnlineSave}
                        handleFilter={this.handleFilter}

                        // postComment={this.handlePostComment}
                        renderFilter={this.renderFilter}

                        relatedList={this.props.relatedList}
                        filter_dates={this.state.filter_dates}
                        setFilterDates={this.setFilterDates}
                        isRowClickable={false}
                        hide_search={true}
                        selected_row={this.state.selected_id}
                        textAlign={'table-text-right'}
                        isChat={false}
                        isRelated={false}
                        isAttachments={false}

                        getFormRef={this.gettableRef}
                    />
                </Suspense>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        Electricity: _.get(state.Electricity, 'data'),
        Selected_Rows: _.get(state.EditableTable, 'selected_rows'),
        Area: _.get(state.Facility.selected, 'record.area'),
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setElectricityRequest: (e) => dispatch(actions.ElectricityRequest(e)),
        setElectricitySuccess: (e) => dispatch(actions.ElectricitySuccess(e)),
        SelectedRows: (ids) => dispatch(EditableTableActions.SelectedRows(ids))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Electricity);
