import React from 'react';
import PermissionControl from '@/components/Permission/Permission';

import Facility from '@/containers/management/Facility/Facility';
import EquipmentCategory from '@/containers/facility/Equipment_Category/Equipment_Category';
import CustomUser from '@/containers/management/UserManagement/CustomUser/CustomUser';
import FAQ from '@/containers/Help/FAQ';
import Company from '@/containers/management/Company/Company';
import Profile from '@/containers/facility/Profile/Profile';
import UserManagementTabs from '@/containers/management/UserManagement/UserManagementTabs';
import FunctionalForm from '@/containers/management/FunctionalForm/FunctionalForm';
import FileTagManage from '@/containers/management/FileTagManage/FileTagManage';

export const companyRoutes = [
  {
    path: 'Company',
    element: <PermissionControl allowedPermissions={'1:company'}><Company /></PermissionControl>,
    name: 'company',
    handle: {
      tab: 'company'
    }
  },
  {
    path: 'FAQ',
    element: <PermissionControl allowedPermissions={'1:faq'}><FAQ /></PermissionControl>,
    name: 'faq',
    handle: {
      tab: 'faq'
    }
  },
  {
    path: 'CustomUser',
    element: <PermissionControl allowedPermissions={'1:user'}><CustomUser /></PermissionControl>,
    name: 'user',
    handle: {
      tab: 'user'
    }
  },
  {
    path: 'Equipment_Category',
    element: <PermissionControl allowedPermissions={'1:equipmentCategory'}><EquipmentCategory /></PermissionControl>,
    name: 'equipmentCategory',
    handle: {
      tab: 'equipment category'
    }
  },
  {
    path: 'Facility',
    element: <PermissionControl allowedPermissions={'1:facility'}><Facility /></PermissionControl>,
    name: 'facility',
    handle: {
      tab: 'facility'
    }
  },
  // {
  //   path: 'profile',
  //   element: <PermissionControl allowedPermissions={'1:profile'}><Profile /></PermissionControl>,
  //   name: 'profile',
  //   handle: {
  //     tab: 'profile'
  //   }
  // },
  {
    path: 'UserManagementTabs',
    element: <PermissionControl allowedPermissions={'1:user'}><UserManagementTabs /></PermissionControl>,
    name: 'user',
    handle: {
      tab: 'user'
    }
  },
  {
    path: 'functionform',
    element: <PermissionControl allowedPermissions={'1:functionform'}><FunctionalForm /></PermissionControl>,
    name: 'functionform',
    handle: {
      tab: 'function form'
    }
  },
  {
    path: 'filetagmanage',
    element: <PermissionControl allowedPermissions={'1:filetagmanage'}><FileTagManage /></PermissionControl>,
    name: 'filetagmanage',
    handle: {
      tab: 'tag'
    }
  },
]


