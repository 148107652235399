import React, {Component} from 'react';
import actions from "@redux/EditableTable/actions";
import {connect} from "react-redux";
import enUS from "antd/lib/locale/en_US";
// import ProTable from "@ant-design/pro-table";
import _ from "lodash";
import Space from "antd/es/space";
import {Button, ConfigProvider, Drawer, Popconfirm} from "antd";
import {CSVLink} from "react-csv";
import ResizableProTable from "@components/Table/ResizableProTable";
import Detail from "@components/Table/Detail";
import moment from "moment";
import {defaultActionRender} from "@ant-design/pro-utils/es/useEditableArray";
import ID from "@components/UUID";
import {RedoOutlined, SettingFilled} from "@ant-design/icons";
import ImportModalComponent from "./Import";
import PermissionControl from "../Permission/Permission";

class TableContent extends Component {

    actionRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            isImportCSVModal: false,
            isNewModalOpen: false,
            searchString: null,
            filter_dates: [null, null],
            editableKeys: [],
            visible: false,
            isOutSideVisible: false,
            data: [],
            valueChanged: false,
            newValues: [],
            open: true,
            hoveredRow: null,
        }

        this.box = React.createRef();
        this.formRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('click', this.handleOutsideClick);
    }


    componentWillReceiveProps(nextProps) {
        // console.log('setFilterDates tablecontent', this.props.filter_dates, nextProps.filter_dates);
        // this.setState({
        //     editableKeys: this.props.data.map((item) => item.id)
        // })
        // console.log('nextprops', this.props, nextProps)
        if (this.props.searchString !== nextProps.searchString
            || nextProps.selected_projectId !== this.props.selected_projectId
            || nextProps.facility_id !== this.props.facility_id) {
            this.setState({
                searchString: nextProps.searchString,
            }, function () {
                // console.log('this.state.searchString4', nextProps.searchString)
                this.actionRef.current.reload();
            })

        } else if (this.props.triggered !== nextProps.triggered) {
            // console.log('setFilterDates change')
            this.setState({
                filter_dates: nextProps.filter_dates,
            })
            this.actionRef.current.reload();
        }
    }


    setProjectId = (id, name) => {
        // console.log('project', id, name)
        if (name) {
            this.props.SelectedProject(id, name)
        }

    }

    handleMouseEnter = (record) => {
        this.setState({ hoveredRow: record.id });
    };

    handleMouseLeave = (e) => {
        try{
            if (e?.relatedTarget && (e?.relatedTarget?.closest('.icon') || e?.relatedTarget?.closest('.hover-div'))) {
                return;
            }
            this.setState({ hoveredRow: null });
        } catch (error){
            return;
        }

    };

    deleteRecord = async (e) => {
        let result = await this.props.deleteRecord(e);
        this.props.CloseModalRequest(true)
        this.props.OpenDrawer(false);
        this.actionRef.current.reload();

    }


    handOnlineSave = async (key, row) => {

        let keys = this.state.data.map(e => e.id)
        let result;
        // console.log('handOnlineSave', keys, key, row, keys.includes(key))
        if (!keys.includes(key)) {
            // console.log('row', row)
            result = await this.props.createRecords(row);
        } else {
            result = await this.props.handOnlineSave(key, row);
        }

        // jsforceResponse(result, '', false, true)
        //  do not close the drawer, because user table has two drawers open at the same time
        // this.props.OpenDrawer(false);

        this.setState({
            valueChanged: false
        }, function () {
            this.actionRef.current.reload();
        })

        return result
    }

    createRecords = async (e) => {
        let result = await this.props.createRecords(e);
        this.actionRef.current.reload();
    }

    handleSaveComment = async (key, row) => {
        let result;
        result = await this.props.handOnlineSave(key, row);
        // console.log('handOnlineSave', result);
    }

    // openDetails = (e) => {
    //     this.setState({
    //         isDetail: true
    //     })
    // }


    handleImport = (e) => {
        this.props.CloseImportModalRequest(false)
    }

    handleNewModalOpen = (e) => {
        this.props.CloseModalRequest(false)
    }

    handleNewModalClose = (e) => {
        this.props.CloseModalRequest(true)
    }

    handleNewModalSubmit = async (e) => {
        let result = await this.props.createRecords(e);
        this.props.CloseModalRequest(true)
        this.actionRef.current.reload();
    }

    deleteSelected = async (e, body) => {
        let result = await this.props.bulkDelete(body)
        // console.log('delete', result)
        this.actionRef.current.reload();
        this.actionRef.current.clearSelected();
        this.setState({
            visible: false
        })
    }


    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        // console.log('selectedKeys', dataIndex, selectedKeys[0])
        this.setState({
            ['Search_' + dataIndex]: selectedKeys[0],
        }, this.Query);
    };

    Query = () => {
        this.props.handleGlobalSearch('');
        let state = Object.keys(this.state).filter(e => e.startsWith('Search'));
        let query = [];
        for (let i = 0; i < state.length; i++) {
            query += state[i].split('_')[1] + '=' + this.state[state[i]] + '&'
        }
        // console.log('query', query)
        this.props.handleFilter(query)
        this.actionRef.current.reload();
    }

    handleReset = (clearFilters) => {
        clearFilters();
        let state = Object.keys(this.state).filter(e => e.startsWith('Search'));
        for (let i = 0; i < state.length; i++) {
            this.setState({
                [state[i]]: '',
            })
        }
        this.Query()
    };

    handleFresh = (e) => {
        // console.log('refresh')
        this.props.handleGlobalSearch('')
        this.setState({
            searchString: null
        }, function () {
            this.actionRef.current.reload();
        })

    }

    refresh = (needRequest = true) => {
        this.setState({
            needRequest,
        }, this.actionRef.current.reload)
    };


    onClose = () => {
        this.props.OpenDrawer(false);
    };

    formRefCallback = (formRef) => {

        this.props.getFormRef(formRef);
    }

    handleRegister = (e, data) => {
        this.props.handleRegister(e, data)
    }
    renderDrawer = (data) => {
        const drawerKey = data && data.id ? `tablecontent_drawer_${data.id}` : 'tablecontent_drawer_default';
        return (
            <>
                {!this.props.relatedList && this.props.formFields && data ? <Drawer
                    key={drawerKey}
                    width={window.innerWidth > 900 ? '100%' : '100%'}
                    placement="right"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.props.drawer}
                    // destroyOnClose={true}
                    autoFocus={true}
                >
                    <div>
                        {this.props.drawer ?
                            <Detail
                                readonly={this.props.readonly}
                                isAdditionalForm={this.props.isAdditionalForm}
                                renderAdditionalForm={this.props.renderAdditionalForm}
                                formClassName={this.props.formClassName}
                                key={'tablecontent_detail'}
                                formKey={'tablecontent_detail'}
                                formFields={this.props.formFields}
                                postComment={this.props.postComment}
                                formRelated={this.props.formRelated}
                                handOnlineSave={this.handOnlineSave}
                                createRecords={this.createRecords}
                                handleSaveComment={this.handleSaveComment}
                                handleDelete={this.deleteRecord}
                                columnProps={this.props.columnProps}
                                userPermission={this.props.userPermission}
                                onClose={this.onClose}
                                isChat={this.props.isChat}
                                isRelated={this.props.isRelated}
                                isAttachments={this.props.isAttachments}
                                isResetPassword={this.props.isResetPassword}
                                formRefCallback={this.formRefCallback}
                                handleRegister={this.handleRegister}
                                data={data}/> : ''}
                    </div>
                </Drawer> : ''}
            </>
        )
    }
    showPopconfirm = (e) => {
        this.setState({
            visible: true
        })
    }
    handleConfirmCancel = (e) => {
        this.setState({
            visible: false
        })

    }


    onSelectChange = selectedRowKeys => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        //when selected, disable edit
        this.setState({selectedRowKeys, editableKeys: []});
    };

    filterExportRows = (rows, columns) => {
        let exportRow = [];
        // console.log('filterExportRows: ', rows)
        for (let i = 0; i < rows.length; i++) {

            let row = {}
            for (let j = 0; j < columns.length; j++) {
                if (!columns[j].exportIngore) {
                    let key = columns[j].key
                    if (key) {
                        let keys = key.split('.');
                        if (keys.length > 1) {
                            // Handle nested properties
                            row[key] = rows[i][keys[0]] ? rows[i][keys[0]][keys[1]] : undefined;
                        } else {
                            // Direct property access
                            row[key] = rows[i][key];
                        }
                    }

                }
            }
            exportRow.push(row)
        }
        return exportRow
    }

    handleOutsideClick = (event) => {

        if (this.box.current
            && this.state.editableKeys.length > 0
            && event.target.className.baseVal !== ''
            && !_.includes(_.get(event.target, 'className', ''), 'ant-select')
            && !_.includes(_.get(event.target, 'className', ''), 'ant-picker')) {
            if (!this.box.current.contains(event.target) || !this.box.current) {

                if (this.state.valueChanged) {
                    this.setState({
                        isOutSideVisible: true
                    })
                }
                if (event.target.className !== 'fas fa-edit text-lg') {
                    // this.setState({
                    //     editableKeys: []
                    // })
                }

            }

        }
    }

    handleOutSideConfirm = async (e) => {

        await this.handOnlineSave(_.get(this.state.newValues, 'id'), this.state.newValues);
        this.setState({
            editableKeys: [],
            isOutSideVisible: false
        })
        this.setState({
            open: false
        }, function () {
            this.setState({
                open: true
            })
        })
    }

    handleOutSideCancel = (e) => {
        this.setState({
            editableKeys: [],
            isOutSideVisible: false,
            newValues: this.state.data,
            valueChanged: false,
        },)
        // this.setState({
        //     open: false
        // }, function () {
        //     this.setState({
        //         open: true
        //     })
        // })
    }

    setEditableRowKeys = (e, row) => {
        this.setState({
            editableKeys: e
        })
    }

    handleValueClicked = (record, recordList) => {
        /*compare new value against old values and detect changes*/
        console.log('handleValueClicked1', record)
        let newValuesdata = this.state.data


        let newValues = record;
        let oldValues = this.state.data.filter(e => e.id === record.id)[0];


        let result = false;
        if (oldValues) {

            for (let i = 0; i < this.state.data.length; i++) {
                if (this.state.data[i].id === record.id) {
                    newValuesdata[i] = record
                }
            }

            for (let i = 0; i < Object.keys(oldValues).length; i++) {
                for (let j = 0; j < Object.keys(newValues).length; j++) {
                    if (oldValues[Object.keys(oldValues)[i]] === newValues[Object.keys(newValues)[j]] &&
                        Object.keys(oldValues)[i] === Object.keys(newValues)[j]
                    ) {
                        result = true;
                    }
                }
            }
        } else {

            let oldValuesData = this.state.data.filter(e => e.id !== record.id);
            newValuesdata = [...oldValuesData, record]
            if (this.state.data.length > 0 && this.state.data.filter(e => e.id === record.id).length === 0) {
                result = true;
            }
        }
        this.setState({
            valueChanged: result,
            newValues: record,
            // data: newValuesdata,
            editableKeys: [record?.id]
        })
    }

    handleCloseImportModalRequest = (e) => {
        this.props.onCloseImportModalRequest(e)
    }

    getRowKeyPosition = () => {

        if (!this.state.hoveredRow || !this.props.isRowHover) return { top: 0, right: 0 };

        const row = document.querySelector(`tr[data-row-key="${this.state.hoveredRow}"]`);
        if (!row) return { top: 0, right: 0 };

        const rect = row.getBoundingClientRect();
        return {
            top: rect.top - 100,
        };
    };
    render() {
        const { hoveredRow } = this.state;
        let columns = this.props.columns;

        if (this.props.relatedList) {
            columns = columns.filter(e => e.title !== 'Operation')
        }

        let params = [
            this.state.searchString,
        ]

        const {top, right} = this.getRowKeyPosition();

        return (
            <>
                {this.state.open ? <div className={'editable'} ref={this.box}>

                    <ConfigProvider locale={enUS}>
                        <Popconfirm
                            title="You have unsaved changes, do you want to save them"
                            visible={this.state.isOutSideVisible}
                            onConfirm={() => this.handleOutSideConfirm()}
                            onCancel={() => this.handleOutSideCancel()}
                        >

                            <ResizableProTable
                                key={'tablecontent_editable' + ID}
                                columnsState={{
                                    persistenceKey: 'pro-table-singe-demos',
                                    persistenceType: 'localStorage',
                                    defaultValue: this.props.defaultDisplayColumns,
                                    // onChange: (e) => {
                                    //     console.log(e)
                                    // }
                                }}
                                ref={this.formRef}
                                toolBarRender={() => (
                                    this.props.pageHeader
                                )}
                                options={{
                                    density: false,
                                    reload: false,
                                    refreshIcon: <RedoOutlined/>,
                                    setting: {
                                        settingIcon: <SettingFilled/>,
                                    }
                                }}
                                expandedRowRender={this.props.expandedRowRender}
                                editable={{

                                    type: 'multiple',
                                    onDelete: this.deleteRecord,
                                    deletePopconfirmMessage: 'delete this row',
                                    onSave: (keys, row) => this.handOnlineSave(keys, row),
                                    // editableKeys: this.state.editableKeys,
                                    onChange: this.setEditableRowKeys,
                                    onlyAddOneLineAlertMessage: 'only add one line',
                                    actionRender: (row, config, dom) => {
                                        const configLocal = {...config};
                                        console.log('configLocal',row,configLocal)
                                        configLocal.saveText = <i className="fas fa-save mr-2 ml-2 text-lg"></i>;
                                        configLocal.deleteText = <i className="fas fa-trash-alt text-lg"></i>;
                                        configLocal.cancelText =
                                            <i className="fas fa-times-circle mr-2 ml-2 text-lg"></i>;

                                        const result = defaultActionRender(row, configLocal);
                                        // return result;
                                        return [
                                            React.cloneElement(dom.save,{saveText: <i className="fas fa-save mr-2 ml-2 text-lg"></i>}),
                                            result[1],
                                            React.cloneElement(dom.cancel,
                                                {cancelText: <i className="fas fa-times-circle mr-2 ml-2 text-lg"></i>})];
                                    },
                                    onValuesChange: (record, recordList) => this.handleValueClicked(record, recordList)
                                }}
                                value={this.state.data}
                                showSorterTooltip={false}
                                recordCreatorProps={false}
                                rowClassName={(record) => {
                                    let rowClass = '';
                                    if (
                                        this.props.selected_row === record.id ||
                                        (_.get(record, 'facility_id') && this.props.selected_row === _.get(record, 'facility_id'))
                                    ) {
                                        rowClass += ' selected_row';
                                    }
                                    return rowClass;
                                }}
                                columns={columns}
                                actionRef={this.actionRef}
                                scroll={this.props.scroll ? this.props.scroll : {x: 800}}
                                params={params}
                                postData={(data) => {
                                    this.props.getRecord(data)
                                    this.props.CloseImportModalRequest(true)
                                    this.props.CloseModalRequest(true)
                                    return data
                                }}
                                request={async (params = {}, sort = {}, filter) => {
                                    console.log("params", params, ' sort', sort, 'filter: ', filter);

                                    let query = 'page=' + params.current + '&page_size=' + params.pageSize + '&';

                                    for (const [key, value] of Object.entries(sort)) {
                                        if (value === 'descend') {
                                            query += "ordering=-" + key + "&";
                                        } else if (value === 'ascend') {
                                            query += "ordering=" + key + "&";
                                        }
                                    }

                                    if (this.props.relatedList && this.props.relatedId && this.props.relatedObject) {
                                        query += '&relatedId=' + this.props.relatedId + '&relatedObject=' + this.props.relatedObject;
                                    }
                                    let result = await this.props.handleFilter(query);
                                    let result_data = _.get(result, 'data', []);

                                    this.setState({
                                        data: result_data,
                                    })

                                    return result
                                }}
                                rowSelection={
                                    {
                                        selections: false,
                                        onChange: this.onSelectChange,
                                    }}
                                tableAlertRender={(e) => {
                                    //when row is selected
                                    this.props.SelectedRows(e.selectedRowKeys)

                                    return <Space size={24}>
                                      <span>
                                        Selected {e.selectedRowKeys.length}
                                          <a style={{marginLeft: 8}} onClick={e.onCleanSelected}>
                                          Cancel Selection
                                        </a>
                                      </span>
                                    </Space>
                                }}
                                tableAlertOptionRender={(e) => {
                                    let rowIds = _.get(e, 'selectedRowKeys', []);
                                    let rows = _.get(e, 'selectedRows', []);

                                    if (!rows[0]) {
                                        rows = []
                                        if (rowIds) {
                                            rowIds.map(e => {
                                                return rows.push(e)
                                            })
                                        } else {
                                            rows = []
                                        }
                                    }

                                    // console.log('rows', exportRow)

                                    return (
                                        <Space size={16}>

                                            <div>
                                                {this.props.customButton && rows.length>0 ? this.props.customButton(rows) : ''}
                                            </div>

                                            <Popconfirm
                                                title="Do you want to delete this record"
                                                visible={this.state.visible}
                                                onConfirm={() => this.deleteSelected(e, rows)}
                                                onCancel={() => this.handleConfirmCancel()}
                                            >
                                                <PermissionControl
                                                    allowedPermissions={'3:' + this.props.columnProps?.name}>
                                                    <Button type={'primary'} onClick={() => this.showPopconfirm()}>Delete
                                                        Selected</Button>
                                                </PermissionControl>
                                            </Popconfirm>

                                            <Button>
                                                <CSVLink filename={'export_data_' + moment().format() + '.csv'}
                                                         data={this.filterExportRows(rows, columns)}>Export</CSVLink>
                                            </Button>


                                        </Space>
                                    );
                                }}
                                rowKey="id"
                                search={false}
                                pagination={{
                                    showSizeChanger: true,
                                    pageSizeOptions: [10, 20, 50, 100, 1000],
                                    showQuickJumper: true,
                                    showTotal: (total, range) => (
                                        <div>{`Showing ${range[0]}-${range[1]} of ${total} total items`}</div>
                                    ),
                                }}

                                onRow={(record, recordIndex) => {
                                    // when each row cell is clicked
                                    // console.log('selected', record)
                                    if (!this.props.permission[this.props.columnProps.name] > 1 || this.props.Role === '2.2' || this.props.Role === '4.0') {
                                        return null
                                    }
                                    if (this.props.isRowClickable) {
                                        return {
                                            onClick: event => {
                                                console.log(this.state.editableKeys)

                                                if (this.state.editableKeys.length > 0) {
                                                    let editableKeys = null;
                                                    editableKeys = this.state.editableKeys[0]
                                                    this.actionRef.current.cancelEditable(editableKeys);
                                                }

                                                this.actionRef.current.startEditable(record.id)
                                            }
                                        }
                                    } else if (this.props.isRowSelectable) {
                                        return {
                                            onClick: event => this.setProjectId(record.id, record.name)
                                        }
                                    } else if(this.props.isRowHover) {
                                        return {
                                            onMouseEnter: () => this.handleMouseEnter(record),
                                            onMouseLeave: (e) => this.handleMouseLeave(e),
                                        }
                                    } else {
                                        return {

                                            onDoubleClick: event => {
                                                this.props.OpenDrawer(true, record.id)
                                                // this.renderDrawer(record, {'id': record.id})
                                            },


                                        }
                                    }

                                }}
                            />
                        </Popconfirm>

                        <ImportModalComponent
                            columnProps={this.props.columnProps}
                            isImportModalClosed={this.props.isImportModalClosed}
                            CloseImportModalRequest={this.handleCloseImportModalRequest}
                            bulkInsert={this.props.bulkInsert}
                            columns={columns}
                        />

                        {this.props.isRowHover && hoveredRow && (
                            <div
                                className="hover-div flex fixed absolute w-38 h-14 pl-10 pr-4 justify-center items-center z-50"
                                style={{
                                    backgroundColor:'#FAFAFA',
                                    top: `${top}px`,
                                    right: `30px`,
                                    transform: 'translateY(-50%)',
                                }}
                                onMouseEnter={(e) => e.stopPropagation()}
                                onMouseLeave={(e) => e.stopPropagation()}
                            >
                                <a><i className="icon fa-regular fa-trash-can text-lg" onClick={() => this.deleteRecord(hoveredRow)}></i></a>
                            </div>
                        )}
                        {!this.props.relatedList ? this.renderDrawer(_.filter(this.props.data, {'id': this.props.id})[0]) : ''}
                    </ConfigProvider>
                </div> : ''}
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        loading: state.EditableTable.load,
        drawer: state.EditableTable.drawer,
        id: state.EditableTable.id,
        facility_id: _.get(state.Facility, 'selected.Id'),
        selected_projectId: _.get(state.Project, 'selectedProject.id'),
        Role: _.get(state, 'Auth.role') ? Object.keys(_.get(state, 'Auth.role'))[0] : null,
        permission: state.Auth.permission,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        CloseModalRequest: (e) => dispatch(actions.CloseModalRequest(e)),
        CloseImportModalRequest: (e) => dispatch(actions.CloseImportModal(e)),
        OpenDrawer: (e, id) => dispatch(actions.OpenDrawer(e, id)),
        SelectedProject: (id, name) => dispatch(actions.SelectedProject(id, name)),
        SelectedRows: (ids) => dispatch(actions.SelectedRows(ids))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {forwardRef: true}
)(TableContent);
